import { useEffect, useRef } from "react";
import { useElementSize } from "../../../global/components/ImageWrapper/ImageWrapper.methods";
import { getModifiedSectionUuid } from "../../../helpers/getModifiedSectionUuid";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { useSectionEffectsContext } from "../../Contexts/SectionEffectsContext";
import { ISectionProps, usePrepareSectionSettings } from "../Section";
import { StyledStickySectionEffects } from "./SectionEffects.styled";
import { useRowPopupContext } from "../../../structure/Contexts/RowPopupContext";
import { isClientSide } from "@ecp-redux/api/api";

export type TSectionEffects = Omit<ISectionProps, "path"> & {
  children: JSX.Element;
};

const SectionEffects = ({ children, sectionData, type }: TSectionEffects) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, height] = useElementSize(ref);
  const isMobile = useIsMobilePortal();
  const { setSectionEffectsData } = useSectionEffectsContext();
  const { activePopup } = useRowPopupContext();
  const displaySettings = usePrepareSectionSettings(
    sectionData.displaySettings,
    type
  );

  const activePopupHeight =
    (isClientSide()
      ? document?.getElementById(`SECTION=${activePopup?.sectionData.id}`)
          ?.offsetHeight
      : 0) ?? 0;

  useEffect(() => {
    setSectionEffectsData(
      getModifiedSectionUuid(sectionData, isMobile),
      sectionData.uuid != activePopup?.sectionData.uuid
        ? height
        : height - activePopupHeight,
      ref
    );
  }, [height, isMobile, ref, ref.current]);

  return (
    <StyledStickySectionEffects
      id={`${sectionData.id}`}
      data-sectionuuid={getModifiedSectionUuid(sectionData, isMobile)}
      $sectionUuid={getModifiedSectionUuid(sectionData, isMobile)}
      $displaySettings={displaySettings}
      ref={ref}
    >
      {children}
    </StyledStickySectionEffects>
  );
};

export default SectionEffects;
