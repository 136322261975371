import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  TButtonReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { z } from "zod";
import { ILinkContent } from "./../../contentPatterns.types";

export type IBoxButtonData = GenericBoxData<
  IBoxButtonContent,
  IBoxButtonContentSettings,
  IBoxButtonDisplaySettings,
  IBoxButtonMessages,
  IBoxButtonSettings
>;

export interface IBoxButtonContent {
  title: string;
  buttonLabel: string;
  buttonLink: ILinkContent;
}

export interface IBoxButtonContentSettings {
  buttonAlignment: AlignmentHorizontalOption;
  buttonStyle: TButtonReadoutValue;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBoxButtonDisplaySettings {}

export const BoxButtonMessagesScheme = z.object({
  add_compare_product_snackbar: themeScheme.MessageScheme.default(
    "Dodano produkt do porównania"
  ),
  remove_compare_product_snackbar: themeScheme.MessageScheme.default(
    "Usunięto produkt z porównania"
  ),
  error_message: themeScheme.MessageScheme.default(
    "Błąd dodawania do porównania"
  ),
});

export const BoxButtonSettingsScheme = z.object({
  add_compare_product_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  remove_compare_product_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  error_message_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
});

export type IBoxButtonMessages = z.infer<typeof BoxButtonMessagesScheme>;
export type IBoxButtonSettings = z.infer<typeof BoxButtonSettingsScheme>;
