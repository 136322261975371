import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  SettingActive,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxMyAccountMyAddressListMessagesScheme = z.object({
  myaccount_address_list_delivery_address_title:
    themeScheme.MessageScheme.default("Adresy dostawy"),
  myaccount_address_list_invoice_address_title:
    themeScheme.MessageScheme.default("Adresy do faktury"),
  myaccount_address_list_button_add__address:
    themeScheme.MessageScheme.default("Dodaj adres"),
  myaccount_address_list_main_address:
    themeScheme.MessageScheme.default("Adres domyślny"),
  myaccount_address_list_addresses_popup_title:
    themeScheme.MessageScheme.default("Czy chcesz usunąć adres?"),
  myaccount_address_list_addresses_popup_description:
    themeScheme.MessageScheme.default(
      "W każdej chwili możesz edytować swoją listę adresów"
    ),
  myaccount_address_list_addresses_popup_button_save:
    themeScheme.MessageScheme.default("Potwierdź"),
  myaccount_address_list_addresses_popup_button_cancel:
    themeScheme.MessageScheme.default("Anuluj"),
  myaccount_address_list_edit_delivery_title:
    themeScheme.MessageScheme.default("Nowy adres dostawy"),
  myaccount_address_list_edit_delivery_heading_name:
    themeScheme.MessageScheme.default("Imię"),
  myaccount_address_list_edit_delivery_placeholder_name:
    themeScheme.MessageScheme.default("Imię"),
  myaccount_address_list_edit_delivery_heading_surname:
    themeScheme.MessageScheme.default("Nazwisko"),
  myaccount_address_list_edit_delivery_placeholder_surname:
    themeScheme.MessageScheme.default("Nazwisko"),
  myaccount_address_list_edit_delivery_heading_street:
    themeScheme.MessageScheme.default("Ulica"),
  myaccount_address_list_edit_delivery_placeholder_street:
    themeScheme.MessageScheme.default("Ulica"),
  myaccount_address_list_edit_delivery_heading_house:
    themeScheme.MessageScheme.default("Numer domu"),
  myaccount_address_list_edit_delivery_placeholder_house:
    themeScheme.MessageScheme.default("Numer domu"),
  myaccount_address_list_edit_delivery_heading_flat:
    themeScheme.MessageScheme.default("Numer lokalu"),
  myaccount_address_list_edit_delivery_placeholder_flat:
    themeScheme.MessageScheme.default("Numer lokalu"),
  myaccount_address_list_edit_delivery_heading_city:
    themeScheme.MessageScheme.default("Miasto"),
  myaccount_address_list_edit_delivery_placeholder_city:
    themeScheme.MessageScheme.default("Miasto"),
  myaccount_address_list_edit_delivery_heading_country:
    themeScheme.MessageScheme.default("Kraj"),
  myaccount_address_list_edit_delivery_heading_zip_code:
    themeScheme.MessageScheme.default("Kod pocztowy"),
  myaccount_address_list_edit_delivery_placeholder_zip_code:
    themeScheme.MessageScheme.default("Kod pocztowy"),
  myaccount_address_list_edit_delivery_heading_phone:
    themeScheme.MessageScheme.default("Numer telefonu"),
  myaccount_address_list_edit_delivery_placeholder_phone:
    themeScheme.MessageScheme.default("Numer telefonu"),
  myaccount_address_list_edit_delivery_empty_verification_inputs:
    themeScheme.MessageScheme.default("Uzupełnienie pola jest wymagane"),
  myaccount_address_list_edit_delivery_syntax_verification_phone:
    themeScheme.MessageScheme.default("Numer telefonu musi mieć 9 cyfr"),
  myaccount_address_list_edit_delivery_syntax_verification_zip_code:
    themeScheme.MessageScheme.default("Wypełnij poprawnie kod pocztowy"),
  myaccount_address_list_edit_delivery_default_address:
    themeScheme.MessageScheme.default("Ustaw jako domyślny adres"),
  myaccount_address_list_edit_delivery_save_button:
    themeScheme.MessageScheme.default("Zapisz"),
  myaccount_address_list_edit_delivery_cancel_button:
    themeScheme.MessageScheme.default("Anuluj"),
  myaccount_address_list_edit_delivery_consent_success:
    themeScheme.MessageScheme.default("Dodałeś nowy adres do dostawy"),
  myaccount_address_list_edit_invoice_title: themeScheme.MessageScheme.default(
    "Podaj adres do faktury"
  ),
  myaccount_address_list_edit_invoice_heading_name:
    themeScheme.MessageScheme.default("Imię"),
  myaccount_address_list_edit_invoice_placeholder_name:
    themeScheme.MessageScheme.default("Imię"),
  myaccount_address_list_edit_invoice_heading_surname:
    themeScheme.MessageScheme.default("Nazwisko"),
  myaccount_address_list_edit_invoice_placeholder_surname:
    themeScheme.MessageScheme.default("Nazwisko"),
  myaccount_address_list_edit_invoice_heading_street:
    themeScheme.MessageScheme.default("Adres"),
  myaccount_address_list_edit_invoice_placeholder_street:
    themeScheme.MessageScheme.default("Ulica"),
  myaccount_address_list_edit_invoice_placeholder_house:
    themeScheme.MessageScheme.default("Numer domu"),
  myaccount_address_list_edit_invoice_placeholder_flat:
    themeScheme.MessageScheme.default("Numer lokalu"),
  myaccount_address_list_edit_invoice_heading_city:
    themeScheme.MessageScheme.default("Miasto"),
  myaccount_address_list_edit_invoice_placeholder_city:
    themeScheme.MessageScheme.default("Miasto"),
  myaccount_address_list_edit_invoice_heading_country:
    themeScheme.MessageScheme.default("Kraj"),
  myaccount_address_list_edit_invoice_placeholder_country:
    themeScheme.MessageScheme.default("Kraj"),
  myaccount_address_list_edit_invoice_placeholder_zip_code:
    themeScheme.MessageScheme.default("Kod pocztowy"),
  myaccount_address_list_edit_invoice_heading_phone:
    themeScheme.MessageScheme.default("Numer telefonu"),
  myaccount_address_list_edit_invoice_placeholder_phone:
    themeScheme.MessageScheme.default("Numer telefonu"),
  myaccount_address_list_edit_invoice_heading_nip:
    themeScheme.MessageScheme.default("NIP"),
  myaccount_address_list_edit_invoice_placeholder_nip:
    themeScheme.MessageScheme.default("NIP"),
  myaccount_address_list_edit_invoice_heading_company_name:
    themeScheme.MessageScheme.default("Nazwa firmy"),
  myaccount_address_list_edit_invoice_placeholder_company_name:
    themeScheme.MessageScheme.default("Nazwa firmy"),
  myaccount_address_list_edit_invoice_heading_company:
    themeScheme.MessageScheme.default("Firma"),
  myaccount_address_list_edit_invoice_heading_private:
    themeScheme.MessageScheme.default("Osoba prywatna"),
  myaccount_address_list_edit_invoice_empty_verification_inputs:
    themeScheme.MessageScheme.default("Uzupełnienie pola jest wymagane"),
  myaccount_address_list_edit_invoice_syntax_verification_phone:
    themeScheme.MessageScheme.default("Numer telefonu musi mieć 9 cyfr"),
  myaccount_address_list_edit_invoice_syntax_verification_zip_code:
    themeScheme.MessageScheme.default("Wypełnij poprawnie kod pocztowy"),
  myaccount_address_list_edit_invoice_save_button:
    themeScheme.MessageScheme.default("Zapisz"),
  myaccount_address_list_edit_invoice_cancel_button:
    themeScheme.MessageScheme.default("Anuluj"),
  myaccount_address_list_edit_invoice_consent_success:
    themeScheme.MessageScheme.default("Dodałeś nowy adres do faktury"),
  myaccount_address_list_store_address_title:
    themeScheme.MessageScheme.default("Adresy sklepów"),
  store_locator_open_hours:
    themeScheme.MessageScheme.default("Godziny otwarcia:"),
  storelocator_view_label: themeScheme.MessageScheme.default("Widok: "),
  store_search_bar_search_input_placeholder_text:
    themeScheme.MessageScheme.default("Wpisz miejscowość, kod pocztowy"),
  storelocator_select_address_label:
    themeScheme.MessageScheme.default("Wybierz adres"),
  storelocator_not_found: themeScheme.MessageScheme.default(
    "Nie znaleziono sklepu"
  ),
  myaccount_address_list_edit_invoice_wrong_nip_message:
    themeScheme.MessageScheme.default("Podano błędny NIP"),
  storelocator_mobile_show_map: themeScheme.MessageScheme.default("Pokaż mapę"),
  storelocator_mobile_show_shop_list:
    themeScheme.MessageScheme.default("Lista sklepów"),
  store_locator_open_hours_monday:
    themeScheme.MessageScheme.default("Poniedziałek"),
  store_locator_open_hours_tuesday: themeScheme.MessageScheme.default("Wtorek"),
  store_locator_open_hours_wednesday:
    themeScheme.MessageScheme.default("Środa"),
  store_locator_open_hours_thursday:
    themeScheme.MessageScheme.default("Czwartek"),
  store_locator_open_hours_friday: themeScheme.MessageScheme.default("Piątek"),
  store_locator_open_hours_saturday:
    themeScheme.MessageScheme.default("Sobota"),
  store_locator_open_hours_sunday:
    themeScheme.MessageScheme.default("Niedziela"),
  myaccount_address_list_inpost_title:
    themeScheme.MessageScheme.default("Punkty InPost"),
  inpost_select_address_label:
    themeScheme.MessageScheme.default("Wybierz punkt"),
  delete_delivery_address_popup_message: themeScheme.MessageScheme.default(
    "Czy na pewno chcesz usunąć tę pozycję z listy adresów?"
  ),
  delete_delivery_address_popup_title: themeScheme.MessageScheme.default(
    "Usuń adres paczkomatu"
  ),
  delete_delivery_address_popup_delete_button_message:
    themeScheme.MessageScheme.default("Usuń"),
  delete_delivery_address_popup_cancel_button_message:
    themeScheme.MessageScheme.default("Anuluj"),
});
export const IBoxMyAccountMyAddressListSettingsScheme = z.object({
  myaccount_address_list_delivery_address_label_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  myaccount_address_list_invoice_address_label_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  myaccount_address_list_store_address_label_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  myaccount_address_list_delivery_address_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_store_address_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_invoice_address_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_delivery_address_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  myaccount_address_list_invoice_address_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  myaccount_address_list_store_address_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  myaccount_address_list_delivery_address_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_invoice_address_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_store_address_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_popup_title_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  myaccount_address_list_popup_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_popup_description_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  myaccount_address_list_popup_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_popup_no_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  myaccount_address_list_popup_yes_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  myaccount_address_list_main_address_typo:
    themeScheme.TypoIdScheme.default("typo$$7").catch("typo$$7"),
  myaccount_address_list_main_address_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_add_address_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  myaccount_address_list_edit_success_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  myaccount_address_list_edit_success_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_edit_success_background_color:
    themeScheme.ColorIdScheme.default("color$$13").catch("color$$13"),
  myaccount_address_list_edit_title_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  myaccount_address_list_edit_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_edit_title_alignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  myaccount_address_list_edit_input_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  myaccount_address_list_edit_half_width_input_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  myaccount_address_list_edit_save_button_typo:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  myaccount_address_list_edit_cancel_button_typo:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  myaccount_address_list_address_background_color:
    themeScheme.ColorIdScheme.default("color$$7").catch("color$$7"),
  myaccount_address_list_popup_redirect_after_address_delete:
    themeScheme.UrlScheme.default("/").catch("/"),
  myaccount_address_list_default_address_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  myaccount_address_list_default_address_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_country_select_value_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  myaccount_address_list_country_select_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  myaccount_address_list_country_select_label_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  myaccount_address_list_country_select_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_store_adress_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  storelocator_store_adress_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_store_open_typography:
    themeScheme.TypoIdScheme.default("typo$$7").catch("typo$$7"),
  storelocator_store_open_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_store_www_typography:
    themeScheme.TypoIdScheme.default("typo$$10").catch("typo$$10"),
  storelocator_store_www_color:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  storelocator_store_picked_color:
    themeScheme.ColorIdScheme.default("color$$7").catch("color$$7"),
  storelocator_image_width:
    themeScheme.SettingNumberScheme.default("232").catch("232"),
  store_search_bar_search_input_settings:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  store_search_bar_search_button_settings:
    themeScheme.ButtonIdScheme.default("button$$3").catch("button$$3"),
  store_search_bar_wrapper_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  storelocator_view_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  storelocator_view_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_select_address_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  storelocator_no_image_plug: themeScheme.UrlScheme.default(
    "/ecp-media-cms/store_locator.png"
  ).catch("/ecp-media-cms/store_locator.png"),
  storelocator_mobile_show_map_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_mobile_show_map_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_mobile_show_shop_list_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_mobile_show_shop_list_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_mobile_show_shop_list_background_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  myaccount_address_list_delivery_address_default_address_typo:
    themeScheme.TypoIdScheme.default("typo$$3").catch("typo$$3"),
  myaccount_address_list_delivery_address_default_address_color:
    themeScheme.ColorIdScheme.default("color$$3").catch("color$$3"),
  storelocator_store_email_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  storelocator_store_email_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_not_found_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_not_found_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  inpost_delivery_visibility: z
    .nativeEnum(SettingActive)
    .default(SettingActive.ON)
    .catch(SettingActive.ON),
  inpost_select_address_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  myaccount_address_list_inpost_title_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  myaccount_address_list_inpost_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  delete_delivery_address_popup_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  delete_delivery_address_popup_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  delete_delivery_address_popup_title_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  delete_delivery_address_popup_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  delete_delivery_address_popup_delete_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  delete_delivery_address_popup_cancel_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  delete_delivery_address_popup_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});
