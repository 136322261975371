import { ButtonReadoutValueScheme } from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { z } from "zod";
import { PathNumberType } from "../shared/portalPath/portalPath";
import { SelectedGoToOption } from "./contentPatterns.types";

export const GoToPageSettingSchema = z.object({
  link: z.string().nullable().default(null),
  openInNewTab: z.boolean().default(false),
});

export const GoToRowSettingSchema = z
  .object({
    rowId: z.union([z.string(), z.number()]).nullable().default(null),
  })
  .default({ rowId: null });

export const ComparisonSettingSchema = z.object({
  addButtonLabel: z.string().default("Add to compare").catch(""),
  addButtonStyle: ButtonReadoutValueScheme.default(
    ButtonReadoutValueScheme.parse({})
  ).catch(ButtonReadoutValueScheme.parse({})),
  removeButtonLabel: z.string().default("Remove from compare").catch(""),
  removeButtonStyle: ButtonReadoutValueScheme.default(
    ButtonReadoutValueScheme.parse({})
  ).catch(ButtonReadoutValueScheme.parse({})),
});

export type IComparisonSetting = z.infer<typeof ComparisonSettingSchema>;

export const PathStringTypeSchema = z.custom<
  | `${"main" | "header" | "footer"}:x,x-section:${PathNumberType},${PathNumberType}-slot:${PathNumberType},${PathNumberType}-stack:${PathNumberType},${PathNumberType}-publication:${PathNumberType},${PathNumberType}`
  | `${"main" | "header" | "footer"}:x,x-section:${PathNumberType},${PathNumberType}-slot:${PathNumberType},${PathNumberType}`
  | `${"main" | "header" | "footer"}:x,x-section:${PathNumberType},${PathNumberType}`
  | ""
  | undefined
>((val) => {
  return (
    val === undefined ||
    val === "" ||
    /^(main|header|footer):x,x-section:(\d+|x),(\d+|x)-slot:(\d+|x),(\d+|x)-stack:(\d+|x),(\d+|x)-publication:(\d+|x),(\d+|x)|(main|header|footer):x,x-section:(\d+|x),(\d+|x)-slot:(\d+|x),(\d+|x)|(main|header|footer):x,x-section:(\d+|x),(\d+|x)|$/.test(
      val as string
    )
  );
});

export const LinkContentScheme = z
  .discriminatedUnion("selectedGoToOption", [
    z.object({
      selectedGoToOption: z.literal(SelectedGoToOption.GO_TO_PAGE),
      goToPageSetting: GoToPageSettingSchema,
      showLinkOptions: z.boolean().optional(),
      goToRowSetting: GoToRowSettingSchema.optional(),
      forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).optional(),
      openPopupSetting: GoToRowSettingSchema.optional(),
      closePopupSetting: GoToRowSettingSchema.optional(),
    }),
    z.object({
      selectedGoToOption: z.literal(SelectedGoToOption.GO_TO_ROW),
      goToRowSetting: GoToRowSettingSchema,
      showLinkOptions: z.boolean().optional(),
      goToPageSetting: GoToPageSettingSchema.optional(),
      forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).optional(),
      openPopupSetting: GoToRowSettingSchema.optional(),
      closePopupSetting: GoToRowSettingSchema.optional(),
    }),
    z.object({
      selectedGoToOption: z.literal(
        SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK
      ),
      goToPageSetting: GoToPageSettingSchema.optional(),
      goToRowSetting: GoToRowSettingSchema.optional(),
      showLinkOptions: z.boolean().optional(),
      forceOrderInsideStack: z
        .record(z.array(PathStringTypeSchema))
        .default({}),
      openPopupSetting: GoToRowSettingSchema.optional(),
      closePopupSetting: GoToRowSettingSchema.optional(),
    }),
    z.object({
      selectedGoToOption: z.literal(SelectedGoToOption.OPEN_POPUP),
      goToPageSetting: GoToPageSettingSchema.optional(),
      goToRowSetting: GoToRowSettingSchema.optional(),
      showLinkOptions: z.boolean().optional(),
      forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).optional(),
      openPopupSetting: GoToRowSettingSchema,
      closePopupSetting: GoToRowSettingSchema.optional(),
    }),
    z.object({
      selectedGoToOption: z.literal(SelectedGoToOption.CLOSE_POPUP),
      goToPageSetting: GoToPageSettingSchema.optional(),
      goToRowSetting: GoToRowSettingSchema.optional(),
      showLinkOptions: z.boolean().optional(),
      forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).optional(),
      openPopupSetting: GoToRowSettingSchema.optional(),
      closePopupSetting: GoToRowSettingSchema,
    }),
    z.object({
      selectedGoToOption: z.literal(SelectedGoToOption.COMPARISON),
      goToPageSetting: GoToPageSettingSchema.optional(),
      goToRowSetting: GoToRowSettingSchema.optional(),
      showLinkOptions: z.boolean().optional(),
      forceOrderInsideStack: z.record(z.array(PathStringTypeSchema)).optional(),
      openPopupSetting: GoToRowSettingSchema.optional(),
      closePopupSetting: GoToRowSettingSchema.optional(),
      comparisonSetting: ComparisonSettingSchema,
    }),
  ])
  .default({
    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
    goToPageSetting: { link: null, openInNewTab: false },
    showLinkOptions: false,
    goToRowSetting: undefined,
    forceOrderInsideStack: undefined,
    openPopupSetting: undefined,
    closePopupSetting: undefined,
  });
